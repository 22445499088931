<template>
  <div>
    <div class="preloader-container">
      <img src="@/assets/images/ched/icons/ched-70px.png" width="70px;" height="70px;" class="preloader">

      <span class="loading-text">
        {{ loading_text }}
      </span>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preloader',


  props: {
    loading_text: String
  }
};
</script>

<style lang="scss" scoped>
/** Preloader 1 */
// .preloader {
//   width: 48px;
//   height: 48px;
//   display: inline-block;
//   position: relative;
// }
// .preloader::after,
// .preloader::before {
//   content: '';  
//   box-sizing: border-box;
//   width: 48px;
//   height: 48px;
//   border: 2px solid #FCD116;
//   position: absolute;
//   left: 0;
//   top: 0;
//   animation: rotation 2s ease-in-out infinite alternate;
// }
// .preloader::after {
//   border-color: #2054A7;
//   animation-direction: alternate-reverse;
// }

// @keyframes rotation {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// } 
/** Preloader 1 ends */

// ---------------------------------------------------------------------------------------- //

/** Preloader 2 */

.preloader {
  animation: rotation 2s ease-in-out infinite;
  transform-origin: center;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/** Preloader 2 ends */

.preloader-container{
  background-color: #EEF5F9;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
}

.loading-text {
  margin-top: 120px; /* Add margin to separate from spinner */
  font-size: 16px;
  position: fixed;
}

.body{
  overflow: hidden !important;
}
</style>