import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import securityOptions from "@/store/securityOptions";
// const BASE_URL            = "http://localhost:8000/api";
// const APP_BASE_URL        = "http://localhost:8000";
const BASE_URL = "https://chedsoapi.chedroxi.com/api";
const APP_BASE_URL = 'https://chedsoapi.chedroxi.com';
// const BASE_URL = "https://sov2api.chedroxi.com/api";
// const APP_BASE_URL = 'https://sov2api.chedroxi.com';
const CSM_URL             = "https://csm.chedroxi.com/api/feedback/get"
const ENHANCED_GUIDELINES = 'https://ro11.ched.gov.ph/wp-content/uploads/2022/10/RM-NO.-030-S.-2022.pdf';

const ls = new SecureLS(securityOptions);
let Store;

Vue.use(Vuex);

try {
  Store =  new Vuex.Store({
    state: {
      APP_BASE_URL: APP_BASE_URL,
      API_URL: BASE_URL,
      CSM_URL: CSM_URL,
      ENHANCED_GUIDELINES: ENHANCED_GUIDELINES,
      Sidebar_drawer: null,
      Customizer_drawer: false,
      SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
      ThemeColor: "#0D47A1",
      darkMode: false,
      SidebarBg: "",
      navbarColor: "primary",
      internalValue: false,
      setHorizontalLayout: false, // Horizontal layout
      isUnderMaintenance: false,
      user: null,
      loading: false,
      is_authenticated: false,
      token: [],
      formData: null,
      rendered_component: null,
      showPreloader: false,
      enhancedGuidelinesAlertIsActive: false,
      recovery: {
        email: '',
        otp_verified: false,
      },
      recovery_default: {
        email: '',
        otp_verified: false,
      },
      institution_photo: {
        orig_file_url: '',
        avatar_url: '',
      },
      institution_photo_default: {
        orig_file_url: '',
        avatar_url: '',
      },
      unread_messages: 0,
      active_chat: {
        chat_code: '',
        id: '',
        image: '',
        lastMessage: '',
        messages: [],
        name: '',
        status: '',
      },  
      default_active_chat: {
        chat_code: '',
        id: '',
        image: '',
        lastMessage: '',
        messages: [],
        name: '',
        status: '',
      }  ,
      selectedItemsInTable: [],
      selectedSOBatchState: 'closed',
      applied_filters: [],
      notifyOn:{
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        time: ''
      },
      notifications: [],
      chat_list: [],
      show_selected: false,

      CSSResponseReceived: localStorage.getItem('already_responded') || "false",
      activeReceiver: localStorage.getItem('receiver') || null
    },
    mutations: {
      SET_SIDEBAR_DRAWER(state, payload) {
        state.Sidebar_drawer = payload;
      },
      SET_CUSTOMIZER_DRAWER(state, payload) {
        state.Customizer_drawer = payload;
      },
      SET_SIDEBAR_COLOR(state, payload) {
        state.SidebarColor = payload;
      },
      SET_THEME_COLOR(state, payload) {
        state.ThemeColor = payload;
      },
      SET_THEME_MODE(state, payload) {
        state.darkMode = payload;
      },
      SET_NAVBAR_COLOR(state, payload) {
        state.navbarColor = payload;
      },
      SET_LAYOUT(state, payload) {
        state.setHorizontalLayout = payload;
      },
      SET_SIDEBAR_LAYOUT(state, payload) {
        state.internalValue = payload;
      },
      setRequestHeader(state, payload){
        axios.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
      },
      isLoading(state, payload){
        state.loading = payload
      },
      isAuthenticated(state, payload){
        state.is_authenticated = payload;
      },
      setToken(state, payload){
        state.token = payload;
      },
      setUserProfile(state, payload){
        state.user = payload;
        state.institution_photo = payload.institution_photo ? payload.institution_photo : state.institution_photo_default;
      },
      setUserPhoto(state, payload){
        if(state.user){
          state.user.photo = payload;
        }
      },
      setUpdatedUserProfile(state, payload){
        if(state.user){
          state.user.profile = payload;
        }
      },
      setRenderedComponent(state, payload){
        state.rendered_component = payload;
      },
      clearCredentials(state){
        state.user = null;
        state.token = [];
        state.is_authenticated = false;
        state.Sidebar_drawer = null;
        state.Customizer_drawer = false;
        // state.SidebarColor = 'white';
        // state.navbarColor = 'primary';
        state.setHorizontalLayout = false;
        state.formData = null;
        state.recovery = Object.assign({}, state.recovery_default);
        state.institution_photo = Object.assign({}, state.institution_photo_default);
        state.active_chat = Object.assign({}, state.default_active_chat);
        state.selectedItemsInTable = [];
        state.notifications = [];
        state.chat_list = [];
        state.show_selected = false;
      },
      SET_FORM_DATA(state, payload){
        state.formData = payload;
      },
      PUSH_NEW_GR(state, payload){
        if(state.formData && state.formData.gr_numbers.length){
          state.formData.gr_numbers.unshift(payload);
        }
      },
      PUSH_NEW_COPC(state, payload){
        if(state.formData && state.formData.copc_numbers.length){
          state.formData.copc_numbers.unshift(payload);
        }
      },
      SET_RECOVERY(state, payload){
        if(!state.recover){
          state.recovery = Object.assign({}, this.recovery_default);
        }
        state.recovery.email = payload;
      },
      SET_RECOVERY_OTP_STATE(state, payload){
        state.recovery.otp_verified = payload;
      },
      SET_INSTITUTION_PHOTO(state, payload){
        state.institution_photo = payload;
      },
      SET_ACTIVE_CHAT(state, payload){
        state.active_chat = payload;
      },
      SET_ACTIVE_CHAT_CODE(state, payload){
        state.active_chat.chat_code = payload;
      },
      SET_MESSAGES(state, payload){
        state.active_chat.messages = payload;
      },
      PUSH_MESSAGE(state, payload){
        state.active_chat.messages.push(payload);
      },
      SET_UNREAD_MESSAGES(state, payload){
        state.unread_messages = payload;
      },
      SET_PRELOADER_STATE(state, payload){
        state.showPreloader = payload;
      },
      SET_MAINTENANCE_MODE(state, payload){
        state.isUnderMaintenance = payload;
      },
      SET_ENHANCED_GUIDELINES_ALERT_STATE(state, payload){
        state.enhancedGuidelinesAlertIsActive = payload;
      },
      SET_SELECTED_ITEMS_IN_TABLE(state, payload){
        state.selectedItemsInTable = payload;
      },
      SET_SELECTED_SO_BATCH_STATE(state, payload){
        state.selectedSOBatchState = payload;
      },
      SET_APPLIED_FILTERS(state, payload){
        state.applied_filters = payload;
      },
      SET_NOTIFICATION_DATE(state, payload){
        state.notifyOn.date = payload;
      },
      SET_NOTIFICATION_TIME(state, payload){
        state.notifyOn.time = payload;
      },
      SET_NOTIFICATIONS(state, payload){
        state.notifications = payload;
      },
      SET_CHAT_LIST(state, payload){
        state.chat_list = payload;
      },
      SET_SHOW_SELECTED(state,payload){
        state.show_selected = payload;
      },
      SET_CSS_RESPONSE_RECEIVED_STATE(state, payload){
        state.CSSResponseReceived = payload;
      },
      SET_ACTIVE_RECEIVER(state, payload){
        state.activeReceiver = payload;
      }
    },
    actions: {
      /**
       * Login and Logout
       */
       signIn(context, payload){
        context.commit('isLoading', true);
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            axios.post(`${BASE_URL}/user/auth/signin`, payload)
            .then((response) => {
              context.commit('isLoading', false);
              context.commit('isAuthenticated', true);
              context.commit('setToken', response.data);
              resolve(response);
            })
            .catch((error) => {
              context.commit('isLoading', false);
              context.commit('isAuthenticated', false);
              reject(error);
            })
          }, 1000)
        })
      },
  
      async signingIn({dispatch},payload){
        await dispatch('signIn', payload);
      },
      
      signOut({commit, getters}){
        return new Promise((resolve, reject) => {
          commit("isLoading", true);
          axios.post(`${BASE_URL}/user/auth/signout`, null , { headers: {"Authorization" : `Bearer ${getters.token.access_token}`}})
          .then((response) => {
            commit('clearCredentials');
            commit("isLoading", false);
            resolve(response);
          })
          .catch((error) => {
            commit('clearCredentials');
            commit("isLoading", false);
            reject(error);
          });
        })
      },
  
      /**
       * Login and Logout Ends
       */
  
      /** Sign Up Starts */
      getRegisterFormData(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/registration/form/data/get`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      validateContacts(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/user/account/validate/contact_info`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      validateFinalAccount(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/user/account/validate/final_account`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      registerAccount(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/user/account/register`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      saveUserHEI(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/user/account/register/hei`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      /** Sign Up Ends */
  
      /**
       ** Password Recovery
       */
       sendOTPforPasswordRecovery(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/user/account/otp/send`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
       verifyOTP(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/user/account/otp/verify`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      resetPassword(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/user/account/set/new_password`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      /**
       * 
       ** Password Recovery ends
       */
  
  
      /*** Getting Current User Info */
      getUserProfile(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/user/auth/profile/get`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
      /** * Getting Current User Info Ends */
  
      /** User Notifications Starts*/
      getUserNotifications(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/user/notifications/get`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      markAllNotificationsAsRead(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/user/notifications/mark_all_as_read`, null, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      removeNotification(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/user/notifications/delete/${payload.id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      markNotificationAsRead(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/user/notifications/mark_as_read`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      markNotificationAsUnread(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/user/notifications/mark_as_unread`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      /** User Notifications Ends */
  
  
      /** Account Profile */
      getAccountProfile(context){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.get(`${BASE_URL}/app/user/account/profile`)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      updateAccountProfile(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.post(`${BASE_URL}/app/user/account/profile/update`, null, { params: payload })
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      updateAccountPassword(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.post(`${BASE_URL}/app/user/account/update/password`, null, {params: payload})
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      updateAccountProfilePhoto(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          let formData = new FormData();
          formData.append('photo', payload);
          axios.post(`${BASE_URL}/app/user/account/profile/photo/update`, formData , {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
    
      
      /** Account Profile Ends */
  
      /**
       * 
       **Insitution Profile Starts
       */    
       getInstitutionProfile(context){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.get(`${BASE_URL}/app/user/institution/profile/get`)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      updateInstitutionPhoto(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          let formData = new FormData();
          formData.append('photo', payload);
          axios.post(`${BASE_URL}/app/user/institution/profile/photo/update`, formData , {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      /**
       * 
       ** Higher Education Institutions CRUD
       */
       getHEIs(context){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.get(`${BASE_URL}/app/heis/get`)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
       /**
        * 
        ** Higher Education Institutions Ends
        */
  
      /**
       * 
       **Institution Profile Ends
       */
  
      /** User Accounts Start */
      getUserAccounts(context){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.get(`${BASE_URL}/app/users/accounts/get`)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      deactivateUserAccount(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.post(`${BASE_URL}/app/users/accounts/deactivate/${payload.id}`)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      activateUserAccount(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.post(`${BASE_URL}/app/users/accounts/activate/${payload.id}`)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      approveUserAccount(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.post(`${BASE_URL}/app/users/accounts/approve/${payload.id}`)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      disapproveUserAccount(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.post(`${BASE_URL}/app/users/accounts/disapprove/${payload.id}`)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      bulkApproveUserAccount(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.post(`${BASE_URL}/app/users/accounts/bulk/approve`, payload)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      updateUserAccount(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.post(`${BASE_URL}/app/users/accounts/update`, payload)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
  
      storeUserAccount(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.post(`${BASE_URL}/app/users/accounts/store`, payload)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },
      /** User Accounts End */


      /**
       * 
       ** Education Supervisor Starts
       */
      getEducationSupervisors(context){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.get(`${BASE_URL}/app/education_supervisors/index`)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },

      updateEducationSupervisorInfo(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          context.commit("isLoading", true);
          axios.post(`${BASE_URL}/app/admin/education_supervisor/update`, payload)
          .then((response) => {
            context.commit("isLoading", false);
            resolve(response);
          })
          .catch((error)=>{
            context.commit("isLoading", false);
            reject(error);
          });
        });
      },




      /**
       * 
       **Education Supervisor Ends
       *  
       */
  
  
      /***
       * * Getting Form Data */
      getFormData(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/get`)
          .then((response) => {
            context.commit('SET_FORM_DATA', response.data);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getHEIsPerRegion(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/heis/get`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getHEIByID(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/hei/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getAvailableBatchesPerHei(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/hei/available_batches`, { params: { institution_id: payload.institution_id } })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      dispatchFetchResponses(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${CSM_URL}`, { params: { name: payload.name } })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getAvailableApplicationReferenceNumbers(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/application_ref_numbers/per_batch/get`, { params: { special_order_id: payload.special_order_id } })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getAvailableBatches(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/avail_batches/get`)
          .then((response) => {
            resolve(response)
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getMaritimePositions(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/maritime/positions/get`)
          .then((response) => {
            resolve(response)
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getShippingCompanies(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/maritime/shipping_companies/get`)
          .then((response) => {
            resolve(response)
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getMaritimeVessels(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/maritime/maritime_vessels/get`, { params: payload })
          .then((response) => {
            resolve(response)
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getMaritimePorts(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/maritime/maritime_ports/get`)
          .then((response) => {
            resolve(response)
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getShipCaptains(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/maritime/ship_captains/get`)
          .then((response) => {
            resolve(response)
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getCoastGuards(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/maritime/coast_guards/get`)
          .then((response) => {
            resolve(response)
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getVoyageTypes(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/maritime/voyage_types/get`)
          .then((response) => {
            resolve(response)
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getOnboardTrainingTypes(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/maritime/onboard_training_types/get`)
          .then((response) => {
            resolve(response)
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
      /** * Getting Form Data Ends */
  
      /*** Getting S.O. Data */
      getSOData(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/so_application/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      getForClaimSOData(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/so_applications/for_claim/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      getSpecialOrderList(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/so_application/list/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getSOProcessLogs(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/so_process_logs/index`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
      /** * Getting S.O. Data Ends */
  
  
      /*** G.R. CRUD */
      storeNewGR(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/gr_and_copc/gr/store`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      updateGRNumber(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/gr_and_copc/gr/update/${payload.id}`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
      /** * G.R. CRUD Ends */
  
      /*** C.O.P.C. CRUD */
      storeNewCOPC(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/gr_and_copc/copc/store`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
  
      updateCOPCNumber(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/gr_and_copc/copc/update/${payload.id}`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
      /** * C.O.P.C. CRUD Ends */
    
  
      /*** S.O. Application CRUD */
      getProgramGRorCOPC(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/gr_and_copc/get/COPC_or_GR/${payload.program_id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      /**
       * 
       **   
       */
  
       createSpecialOrder(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/so_application/create/special_order`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      createSOApplication(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
         
          let formData = new FormData();
          payload.form_9.forEach(file => {
            formData.append('form_9[]', file);
          });
          formData.append('last_name', payload.last_name);
          formData.append('first_name', payload.first_name);
          formData.append('middle_name', payload.middle_name);
          formData.append('extension', payload.extension);
          formData.append('sex_id', payload.sex_id);
          formData.append('program_id', payload.program_id);
          formData.append('major_id', payload.major_id);
          formData.append('research_type', payload.research_type);
          formData.append('research_title', payload.research_title);
          formData.append('semester_id_started', payload.semester_id_started);
          formData.append('academic_year_id_started', payload.academic_year_id_started);
          formData.append('graduation_date', payload.graduation_date);
          formData.append('semester_id', payload.semester_id);
          formData.append('academic_year_id', payload.academic_year_id);
          formData.append('gr_number', payload.gr_number);
          formData.append('copc_number', payload.copc_number);
          formData.append('full_name', payload.full_name);
          formData.append('reference_number', payload.reference_number);
          formData.append('maritime_details', JSON.stringify(payload.maritime_details));

          axios.post(`${BASE_URL}/app/so_application/store`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      updateSOApplication(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          let formData = new FormData();
          payload.form_9.forEach(file => {
            formData.append('form_9[]', file);
          });
          formData.append('id', (payload.id === null) ? '' : payload.id);
          formData.append('special_order_id', (payload.special_order_id === null) ? '' : payload.special_order_id);
          formData.append('institution_id', (payload.institution_id === null) ? '' : payload.institution_id);
          formData.append('last_name', (payload.last_name === null) ? '' : payload.last_name);
          formData.append('first_name', (payload.first_name === null) ? '' : payload.first_name);
          formData.append('middle_name', (payload.middle_name === null) ? '' : payload.middle_name);
          formData.append('extension', (payload.extension === null) ? '' : payload.extension);
          formData.append('sex_id', (payload.sex_id === null) ? '' : payload.sex_id);
          formData.append('program_id', (payload.program_id === null) ? '' : payload.program_id);
          formData.append('major_id', (payload.major_id === null) ? '' : payload.major_id);
          formData.append('research_type', (payload.research_type === null) ? '' : payload.research_type);
          formData.append('research_title', (payload.research_title === null) ? '' : payload.research_title);
          formData.append('semester_id_started', (payload.semester_id_started === null) ? '' : payload.semester_id_started);
          formData.append('academic_year_id_started', (payload.academic_year_id_started === null) ? '' : payload.academic_year_id_started);
          formData.append('graduation_date', (payload.graduation_date === null) ? '' : payload.graduation_date);
          formData.append('semester_id', (payload.semester_id === null) ? '' : payload.semester_id);
          formData.append('academic_year_id', (payload.academic_year_id === null) ? '' : payload.academic_year_id);
          formData.append('gr_number', (payload.gr_number === null) ? '' : payload.gr_number);
          formData.append('copc_number', (payload.copc_number === null) ? '' : payload.copc_number);
          formData.append('full_name', (payload.full_name === null) ? '' : payload.full_name);
          formData.append('reference_number', (payload.reference_number === null) ? '' : payload.reference_number);
          formData.append('maritime_details', JSON.stringify(payload.maritime_details));

          axios.post(`${BASE_URL}/app/so_application/update`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      deleteSOApplicationAttachment(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/so_application/delete/so_attachment`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      processSOApplication(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/so_application/process`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
  
      updateApprovalDetail(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.put(`${BASE_URL}/app/so_application/update/approval/details/${payload.id}`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
  
      updateSpecialOrderSignatoryInAdmin(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.put(`${BASE_URL}/app/so_application/signatory/update`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
  
      updateSpecialOrderPrintingDateByAdmin(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.put(`${BASE_URL}/app/so_application/printing_date/update`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      fetchPDF(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${context.state.APP_BASE_URL}/render/s_o_application/${payload.rand_string}/${payload.ref_number}/${payload.rand_string_mixed}`,
          //  {
          //   // responseType: 'blob'
          //   headers:
          //   {
          //     "Content-type": "application/pdf"
          //   },
          //   responseType: "arraybuffer"
          // }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      bulkPrint(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${context.state.APP_BASE_URL}/render/s_o_application/print/bulk`, payload
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      createCertificationsForPrinting(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${context.state.APP_BASE_URL}/create/certifications/for_printing`, payload
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      renderCertificationsForPrinting(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${context.state.APP_BASE_URL}/render/certifications/for_printing`, payload
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      createSoFormsForPrinting(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${context.state.APP_BASE_URL}/create/so_forms/for_printing`, payload
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      renderSoFormsForPrinting(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${context.state.APP_BASE_URL}/render/so_forms/for_printing`, payload
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      deleteSOApplication(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/so_application/delete`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      cancelSOApplicationApproval(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/so_application/cancel`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
  
      cancelSOApplicationDisapproval(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/admin/special_order/cancel/disapproval`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      /** * S.O. Application CRUD Ends */

    /**
     * 
     **SO Requirements Checker
      */
     checkSoFormRequirement(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        axios.get(`${BASE_URL}/app/so_application/form/requirements/checker`, { params: payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error)=>{
          reject(error);
        });
      });
    },


    checkQualifiedProgramHeadChecker(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        axios.get(`${BASE_URL}/app/so_application/form/requirements/program_head/checker`, { params: payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error)=>{
          reject(error);
        });
      });
    },

    checkQualifiedProgramHeadCheckerBySelection(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        axios.get(`${BASE_URL}/app/so_application/form/requirements/program_head/by_selection/checker`, { params: payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error)=>{
          reject(error);
        });
      });
    },


    /**
     * 
     **SO Requirements Checker Ends
     */
  
  
    /*** Letterhead Data */
      getLetterhead(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/so_application/letterhead/get`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      uploadLetterheadFile(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          let formData = new FormData();
          formData.append('file', payload.file);
          formData.append('filename', payload.filename);
          axios.post(`${BASE_URL}/app/so_application/letterhead/store`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      removeLetterheadFile(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/so_application/letterhead/delete`,)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
      /** * Letterhead Data Ends */
  
  
      uploadSoFile(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          let formData = new FormData();
          formData.append('file', payload.file);
          formData.append('reference_number', payload.reference_number);
          formData.append('filename', payload.filename);
          axios.post(`${BASE_URL}/app/so_application/import/file`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
  
      uploadHEIsDirectoryFile(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          let formData = new FormData();
          formData.append('file', payload.file);
          formData.append('reference_number', payload.reference_number);
          formData.append('filename', payload.filename);
          axios.post(`${BASE_URL}/app/heis/directory_file/upload`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      downloadSoTemplate(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/file/get`,{
            responseType: 'blob',
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

  
      downloadSoMasterlist(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/masterlist/download`,{
            responseType: 'blob',
            params: payload
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      downloadSOMasterlistForUploading(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/masterlist/for_uploading/download`,{
            responseType: 'blob',
            params: payload
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      verifySO(context, payload){
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/so/verifier/get/${payload}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
  
      /** Registrar CRUD Starts */
      getRegistrarsData(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/registrar/management/get`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      saveNewRegistrar(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/registrar/management/store`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      updateRegistrar(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/registrar/management/update`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      updateRegistrarStatus(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/registrar/management/status/update`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      deleteRegistrar(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/registrar/management/delete/${payload}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
      /** Registrar CRUD Ends */
  
  
      /** Program Head CRUD Starts*/
      getProgramHeadData(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/program_head/management/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getProgramHeadListByHEI(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/program_head/management/list/by_HEI/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

  
      updateProgramHeadInformation(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/program_head/management/information/update`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

  
      saveInstitutionProgramHead(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/program_head/management/store`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      updateProgramHead(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/program_head/management/update`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      updateProgramHeadStatus(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/program_head/management/update/status`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      detachProgram(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/program_head/management/detach/program`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      removeProgramHead(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/program_head/management/detach`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      updateProgramStatus(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/program_head/management/status/update`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
      uploadProgramHeadMasterlist(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          let formData = new FormData();
          formData.append('file', payload.file);
          formData.append('filename', payload.filename);
          axios.post(`${BASE_URL}/app/program_head/management/masterlist/upload`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getExistingProgramHeads(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/program_head/management/existing/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      /** Program Head CRUD Ends */
  
  
      /** President CRUD Starts */
      getInstitutionHead(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/institution_head/management/get`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      saveNewInstitutionHead(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/institution_head/management/store`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      updateInstitutionHeadStatus(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/institution_head/management/status/update`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
  
      deleteInstitutionHead(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/institution_head/management/delete/${payload}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      updateInstitutionHead(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/institution_head/management/update`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
      /** President CRUD Ends */
  
      /** Program and Majors CRUD Starts */
      getProgramAndMajorsData(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/programs_and_majors/management/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      getProgramInfo(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/programs_and_majors/management/program_info/get/${payload.id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      getGRInfo(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/gr_and_copc/gr/get/${payload.gr_number}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      getCOPCInfo(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/gr_and_copc/copc/get/${payload.copc_number}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      getPrograms(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/programs/list/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
  
      getMajorsByProgram(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/majors/list/by_programs/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      getMajors(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/majors/list/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      addMajors(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/majors/add`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      downloadProgramsAndMajors(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/file/programs_majors/get`,{
            responseType: 'blob',
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      exportInstitutionPrograms(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/file/institution/programs/export`,{
            responseType: 'blob',
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      exportProgramHeadTemplate(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/file/program_heads/masterlist_file/export`,{
            responseType: 'blob',
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      saveSelectedPrograms(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/programs_and_majors/management/save/programs`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      saveSelectedMajors(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/programs_and_majors/management/save/majors`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      removeAllPrograms(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/programs_and_majors/management/programs/delete`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      addAllPrograms(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.put(`${BASE_URL}/app/programs_and_majors/management/programs/add_all`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      removeProgram(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/programs_and_majors/management/program/remove`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      removeMajor(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/programs_and_majors/management/major/remove`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      removeSelectedPrograms(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/programs_and_majors/management/selected/programs/remove`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      uploadProgramsMasterlist(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          let formData = new FormData();
          formData.append('file', payload.file);
          formData.append('filename', payload.filename);
          axios.post(`${BASE_URL}/app/programs/list/upload`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

  
      /** Program and Majors CRUD Ends */

      /** Chat Functions */
      getUserUnreadMessages(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/messenger/unread/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      sendMessage(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/messenger/send`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      getConversations(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/messenger/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      getChatList(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/messenger/chat_list`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
  
      markMessagesAsRead(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/messenger/mark/as_read`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

  
      searchUsersChatHandler(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/messenger/search/users`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      /** Chat Function Ends */


  
      storeSOApplicationBatch(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/so_batch/store`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      updateSOApplicationBatch(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.put(`${BASE_URL}/app/so_batch/update`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      deleteSOApplicationBatch(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/so_application/batch/delete`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      closeSOApplicationBatch(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/so_batch/close`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      receiveSOApplicationBatch(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/so_batch/receive`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      receiveSOApplication(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/so_process_logs/application/receive`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },



      /**
       ** Admin App
       */
        
       exportMasterlist(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/admin/export/so_masterlist`, {
            responseType: 'blob',
            params: payload
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },





       fetchDashboardStats(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/admin/dashboard/stats/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

        
      getLetterheads(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/admin/letterheads/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

        
      getAllPrograms(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/admin/programs/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
        
      setProgramState(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/admin/program/state/set`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
        
      updateProgramInfo(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.put(`${BASE_URL}/app/admin/program/update/${payload.id}`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

  
      uploadProgramsDirectoryFile(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          let formData = new FormData();
          formData.append('file', payload.file);
          axios.post(`${BASE_URL}/app/admin/programs/import`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

        
      getSpecialOrderNumber(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/admin/special_order/number/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
        
      getAnnouncements(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/admin/announcements/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
        
      postNewAnnouncement(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/admin/announcement/store`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
        
      deleteAnnouncement(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/admin/announcement/delete/${payload.id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

  
      disapproveSOApplication(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          let formData = new FormData();
          axios.post(`${BASE_URL}/app/admin/special_order/disapprove`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      disapproveSOApplicationByBulk(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          let formData = new FormData();
          axios.post(`${BASE_URL}/app/admin/special_order/disapprove/bulk`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      processSOApplicationsByBulk(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          let formData = new FormData();
          axios.post(`${BASE_URL}/app/admin/special_order/process/bulk`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

  
      processSOApplicationByES(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/admin/special_order/process`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

  
      createPrintRequest(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/admin/special_order/create_print_request`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
  
      // Remarks CRUD
      storeRemarks(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/admin/special_order/remarks/store`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      storeRemarksBySelection(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/admin/special_order/selected/remarks/store`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      deleteRemarks(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.delete(`${BASE_URL}/app/admin/special_order/remarks/delete/${payload.id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      updateRemarks(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.put(`${BASE_URL}/app/admin/special_order/remarks/update`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
      // Remarks CRUD ends

      // For Releasing CRUD
      markAsReadyForReleasingBySelection(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/admin/special_order/selected/ready_for_releasing`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      // For Releasing CRUD ends

      // For Claiming CRUD
      getReceiverPersonnel(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/admin/hei/personnel/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      getReceiverPersonnelPerHEI(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.get(`${BASE_URL}/app/form/data/hei/personnel/get`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },


      storeNewReceiverPersonnel(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/admin/receiver/store`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      storeNewReceiverPersonnelByClient(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/form/data/receiver/store`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      markAsClaimedBySelection(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/admin/special_order/selected/claimed`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      markAsClaimedBySelectionByClient(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/so_applications/claim/selected`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },

      markAsUnclaimedBySelectionByClient(context, payload){
        context.commit('setRequestHeader', context.getters.token.access_token);
        return new Promise ((resolve, reject) => {
          axios.post(`${BASE_URL}/app/so_applications/unclaim/selected`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error)=>{
            reject(error);
          });
        });
      },
      // For Claiming CRUD ends

    
      // * Admin App Ends
  
    },
    getters: {
      isLoading(state) {
        return state.loading;
      },
      isAuthenticated(state){
        return state.is_authenticated;
      },
      token(state){
        return state.token;
      },
      getUser(state){
        return state.user;
      },
      navbarColor(state){
        return state.navbarColor;
      },
      getThemeColor(state){
        return state.ThemeColor;
      },
      getFormData(state){
        return state.formData;
      },
      APP_BASE_URL(state){
        return state.APP_BASE_URL;
      },
      API_URL(state){
        return state.API_URL;
      },
      getRenderedComponent(state){
        return state.rendered_component;
      },
      GET_RECOVERY(state){
        return state.recovery ? state.recovery.email : null;
      },
      GET_RECOVERY_OTP_STATE(state){
        return state.recovery ? state.recovery.otp_verified : null;
      },
      GET_INSTITUTION_PHOTO(state){
        return state.institution_photo;
      },
      GET_ACTIVE_CHAT(state){
        return state.active_chat;
      },
      GET_UNREAD_MESSAGES(state){
        return state.unread_messages;
      },
      GET_PRELOADER_STATE(state){
        return state.showPreloader;
      },
      IS_UNDER_MAINTENANCE(state){
        return state.isUnderMaintenance;
      },
      ENHANCED_GUIDELINES(state){
        return state.ENHANCED_GUIDELINES;
      },
      GET_ENHANCED_GUIDELINES_ALERT_STATE(state){
        return state.enhancedGuidelinesAlertIsActive;
      },
      GET_SELECTED_ITEMS_IN_TABLE(state){
        return state.selectedItemsInTable;
      },
      GET_SELECTED_SO_BATCH_STATE(state){
        return state.selectedSOBatchState;
      },
      GET_APPLIED_FILTERS(state){
        return state.applied_filters;
      },
      GET_NOTIFICATION_DATE(state){
        return state.notifyOn.date;
      },
      GET_NOTIFICATION_TIME(state){
        return state.notifyOn.time;
      },
      GET_NOTIFICATIONS(state){
        return state.notifications;
      },
      GET_CHAT_LIST(state){
        return state.chat_list;
      },
      GET_SHOW_SELECTED(state){
        return state.show_selected;
      },
      GET_CSS_RESPONSE(state){
        return state.CSSResponseReceived;
      },
      GET_ACTIVE_RECEIVER(state){
        return state.activeReceiver;
      }
    },
  
    plugins: [
      createPersistedState({
        storage: {
          getItem: key => ls.get(key),
          setItem: (key, value) => ls.set(key, value),
          removeItem: key => ls.remove(key)
        }
      })
    ]
  });  
} catch (error) {
  window.localStorage.clear();
}


export default Store;
