<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">

    <transition name="fade">
      <Preloader v-if="showPreloader" :loading_text="loading_text"/>
    </transition>

    <template v-if="!showPreloader">
      <router-view @signInError="signInError"/>
    </template>

    <v-alert :type="alert_type" elevation="2" class="alert-notif" v-model="alert" transition="scale-transition" dismissible>
      {{ message }}
    </v-alert>

    <v-alert
      prominent
      class="alert-notif"
      dismissible
      color="cyan"
      elevation="5"
      colored-border
      border="bottom"
      style="max-width: 40vw;"
      icon="mdi-text-box-check"
      transition="scale-transition"
      v-model="alert_guidelines"
      @input="toggleGuidelinesAlert"
    >
      <v-row align="center">
        <v-col class="grow">
          View the enhanced guidelines on the application, processing, and issuance of Special Order (S.O).
        </v-col>
        <v-col class="shrink">
          <a :href="ENHANCED_GUIDELINES" target="_blank">
            <v-btn color="primary" class="capitalized-text" depressed>View</v-btn>
          </a>
        </v-col>
      </v-row>
    </v-alert>

    <v-snackbar
      style="z-index:999999 !important;"
      :timeout="-1"
      :value="selected_snackbar"
      color="blue-grey"
      rounded="pill"
      bottom
      elevation="10"
    >
      <h4>Total selected: <strong color="primary"> {{ GET_SELECTED_ITEMS_IN_TABLE.length }} </strong></h4>
    </v-snackbar>

  </v-app>

</template>

<script>
import { EventBus } from '@/event_bus';
import Preloader from './views/preloader/Preloader.vue';

export default {
  name: 'App',

  components: {
    Preloader
  },  

  data() {
    return {
      alert_type: 'info',
      alert: false,
      message: '',
      loading_text: 'CHED RO XI - Special Order is now loading...',
      alert_guidelines: false,
      selected_snackbar: false,
    }
  },

  mounted() {
    this.showGuidelinesAlert();
  },


  computed: {
    showPreloader() {
      return this.$store.getters.GET_PRELOADER_STATE;
    },

    renderedComponent() {
      return this.$store.getters.getRenderedComponent;
    },

    ENHANCED_GUIDELINES(){
      return this.$store.getters.ENHANCED_GUIDELINES;
    },

    GET_ENHANCED_GUIDELINES_ALERT_STATE(){
      return this.$store.getters.GET_ENHANCED_GUIDELINES_ALERT_STATE;
    },

    GET_SELECTED_ITEMS_IN_TABLE: {
      get(){
        return this.$store.getters.GET_SELECTED_ITEMS_IN_TABLE;
      },
      set(value){
        this.$store.commit('SET_SELECTED_ITEMS_IN_TABLE', value);
      }
    }
  
  },

  watch: {
    showPreloader(value) {
      if(!value){
        this.$store.commit('SET_ENHANCED_GUIDELINES_ALERT_STATE', true);
      }
    },

    GET_SELECTED_ITEMS_IN_TABLE(value){
      if(value.length){
        this.selected_snackbar = true;
      }
      else{
        this.selected_snackbar = false;
      }
    }
  },

  methods: {
    toggleGuidelinesAlert(value){
      this.$store.commit('SET_ENHANCED_GUIDELINES_ALERT_STATE', false);
    },  

    signInError(message, alert_type, state) {
        this.message = message;
        this.alert = state;
        this.alert_type = alert_type;

        setTimeout(() => {
          this.alert = !this.alert;
        }, 9000);
    },

    enableCustomEventListener(){
      EventBus.$on('showAlertDialog', (message, alert_type, state) => {
        this.message = message;
        this.alert = state;
        this.alert_type = alert_type;

        setTimeout(() => {
          this.alert = !this.alert;
        }, 1000);
      });
    },

    showGuidelinesAlert(){
      if(this.renderedComponent !== 'verifier'){
        setTimeout(() => {
          if(this.GET_ENHANCED_GUIDELINES_ALERT_STATE){
            this.alert_guidelines = !this.alert_guidelines;
          }
        }, 5000);
      }
    }
  },

  created () {
    this.enableCustomEventListener();
  },
  
};
</script>

<style>
.alert-notif{
  position: fixed !important;
  bottom: 0 !important;
  left: 10px !important;
  z-index: 2000 !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
