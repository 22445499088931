import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/full-layout/Layout"),
      name: 'mainApp',
      redirect: "/special_order/hei/dashboard",
      children: [     
        {
          name: "HeiDashboardComponent",
          path: "special_order/hei/dashboard",
          component: () => import("@/views/ched_so/HEI_APP/so-components/Dashboard"),
        },
        {
          name: "SpecialOrderComponent",
          path: "special_order/main",
          component: () => import("@/views/ched_so/HEI_APP/so-components/SpecialOrderComponent"),
        },
        {
          name: "SpecialOrderAllApplicationsComponent",
          path: "special_order/all/list",
          component: () => import("@/views/ched_so/HEI_APP/so-components/AllApplications"),
        },
        {
          name: "SpecialOrderDetailsComponent",
          path: "special_order/details/:reference_number?",
          component: () => import("@/views/ched_so/common/views/SpecialOrderSubComponent"),
        },
        {
          name: "SettingLetterhead",
          path: "settings/letterhead",
          component: () => import("@/views/ched_so/HEI_APP/setting-components/SettingComponent"),
        },
        {
          name: "SettingSignatories",
          path: "settings/signatories",
          component: () => import("@/views/ched_so/HEI_APP/setting-components/Signatories"),
        },
        {
          name: "SettingProgramsAndMajors",
          path: "settings/programs-and-majors",
          component: () => import("@/views/ched_so/HEI_APP/programs-majors-components/ProgramsComponent"),
        },
        {
          name: "UserAccount&Profile",
          path: "user/account&profile",
          component: () => import("@/views/ched_so/account_profile/AccountProfile"),
        },
        {
          name: "HEIProfile",
          path: "hei/profile",
          component: () => import("@/views/ched_so/HEI_APP/hei-profile-components/Profile"),
        },
        {
          name: "Messenger",
          path: "so/messenger/:chat_code?",
          component: () => import("@/views/ched_so/chat-components/Chat"),
        },
        {
          name: "Notification",
          path: "so/notifications",
          component: () => import("@/views/ched_so/common/Notifications"),
        },
      ],
      meta: {
        permission: 'hei-auth',
      }
    },

    {
      path: "/",
      component: () => import("@/layouts/full-layout/Layout"),
      name: 'adminApp',
      redirect: "/special_order/dashboard",
      children: [     
        {
          name: "DashboardComponent",
          path: "special_order/dashboard",
          component: () => import("@/views/ched_so/ADMIN_APP/components/Dashboard"),
        },
        {
          name: "ESDashboardComponent",
          path: "special_order/es_dashboard",
          component: () => import("@/views/ched_so/ADMIN_APP/components/ESDashboard"),
        },
        {
          name: "AllSpecialOrderApplications",
          path: "special_order/all/applications",
          component: () => import("@/views/ched_so/common/views/SpecialOrderSubComponent"),
        },
        {
          name: "HigherEducationInstitutionsComponent",
          path: "Higher_Education_Institutions/List",
          component: () => import("@/views/ched_so/heis/HEIs"),
        },
        {
          name: "EducationSupervisorsComponent",
          path: "EducationSupervisors/List",
          component: () => import("@/views/ched_so/ADMIN_APP/components/education-supervisors/EducationSupervisors"),
        },
        {
          name: "AnnouncementsComponent",
          path: "Announcements",
          component: () => import("@/views/ched_so/ADMIN_APP/components/announcements/Announcements"),
        },
        {
          name: "ProgramsComponent",
          path: "Programs/List",
          component: () => import("@/views/ched_so/ADMIN_APP/components/programs/Programs"),
        },
        {
          name: "LetterheadsComponent",
          path: "Letterheads/List",
          component: () => import("@/views/ched_so/ADMIN_APP/components/Letterheads"),
        },
        {
          name: "UserAccounts",
          path: "user/accounts",
          component: () => import("@/views/ched_so/accounts/Accounts"),
        }
      ],
      meta: {
        permission: 'admin-auth',
      }
    },

    

    {
      name: "SignIn",
      path: "/authentication/SignIn/:token?",
      component: () => import("@/views/ched_so/authentication/SignIn"),
      meta: {
        permission: 'guest'
      }
    },
    {
      name: "SignUpInstitutionInfo",
      path: "/authentication/Complete/SignUp/:token?",
      component: () => import("@/views/ched_so/sub-components/Stepper/SignUpStepper"),
      meta: {
        permission: 'guest'
      }
    },
    {
      name: "SignUp",
      path: "/authentication/SignUp",
      component: () => import("@/views/ched_so/authentication/SignUp"),
      meta: {
        permission: 'guest'
      }
    },
    {
      name: "PasswordRecovery",
      path: "/authentication/PasswordRecovery",
      component: () => import("@/views/ched_so/authentication/ForgotPassword"),
      meta: {
        permission: 'guest'
      }
    },
    {
      name: "PasswordRecoveryOTP",
      path: "/authentication/PasswordRecovery/OTP",
      component: () => import("@/views/ched_so/authentication/OTPInput"),
      meta: {
        permission: 'guest'
      }
    },
    {
      name: "PasswordRecoveryNewPassword",
      path: "/authentication/PasswordRecovery/New/:rand_code",
      component: () => import("@/views/ched_so/authentication/NewPassword"),
      meta: {
        permission: 'guest'
      }
    },
    {
      name: "PendingAccount",
      path: "/authentication/PendingAccount",
      component: () => import("@/views/authentication/Pending"),
    },
    {
      name: "DeactivatedAccount",
      path: "/authentication/AccountDeactivated",
      component: () => import("@/views/authentication/Deactivated"),
    },
    {
      name: "DisapprovedAccount",
      path: "/authentication/DisapprovedAccount",
      component: () => import("@/views/authentication/Disapproved"),
    },
    {
      name: "AccountNotFound",
      path: "/authentication/AccountDoesntExistError/:email?",
      component: () => import("@/views/authentication/AccountNotFound"),
    },
    {
      name: "SoVerifier",
      path: "/special_order/Verifier/:ref_number?",
      component: () => import("@/views/ched_so/sub-components/verifier/Verifier"),
      meta: {
        permission: 'user-auth'
      }
    },
    {
      name: "MaintenanceState",
      path: "/under_maintenance",
      component: () => import("@/views/ched_so/maintenance/Maintenance"),
      meta: {
        permission: 'guest'
      }
    },
    {
      path: "/claim-section",
      component: () => import("@/layouts/full-layout/HorizontalLayout"),
      children: [
        {
          name: 'Claim Section Starter Page',
          path: 'index/starter_page',
          component: () => import("@/views/ched_so/claim-section/StarterPage"),
        },
        {
          name: 'Client Statisfaction Survey',
          path: 'index/client_satisfaction_survey/:name?',
          component: () => import("@/views/ched_so/claim-section/ClientSatisfactionSurvey"),
        },
        {
          name: 'Claim Section',
          path: 'index/GZFsjHsQfupvmjDlTQRVuwODfOxgmtDFEIP0QCq8l1k2gV265Pmd20zXpQxcl0QkwmKwEd835gFxMdsVHdoZVeSx4INCNT7QJDeQVl5YLuQa2IpE4b3t82pOSAryotHV',
          component: () => import("@/views/ched_so/claim-section/ClaimSection"),
        }

      ],
      meta: {
        permission: 'guest'
      }
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";
import Store from "@/store/store.js";
import { replace } from "lodash";
let store, user, institutions, account_status, user_roles, isUnderMaintenance;
isUnderMaintenance = true;

store = Store;
user = store.getters.getUser;
institutions = user && user.institutions ? user.institutions : [];
account_status = user && user.account_status ? user.account_status.status : null;
user_roles = user && user.user_roles && user.user_roles.length ? user.user_roles : [];

router.beforeEach((to, from, next) => {
  document.title = (to.name?.toString() + ' - Special Order') || "Special Order";

  if(institutions.length && (to.matched.some(record => record.meta.permission === "admin-auth") || to.matched.some(record => record.meta.permission === "hei-auth")) && account_status && account_status.toLowerCase() == 'deactivated'){
    next({name: 'DeactivatedAccount'});
  }
  else if(institutions.length && (to.matched.some(record => record.meta.permission === "admin-auth") || to.matched.some(record => record.meta.permission === "hei-auth")) && account_status && account_status.toLowerCase() == 'disapproved'){
    next({name: 'DisapprovedAccount'});
  }
  else if(institutions.length && to.matched.some(record => record.meta.permission === "hei-auth") && account_status && account_status.toLowerCase() == 'pending'){
    next({name: 'PendingAccount'});
  }
  else if(store.getters.isAuthenticated
          && institutions.length
          && account_status
          && account_status.toLowerCase() == 'active'
          && (to.path == "/authentication/PendingAccount" || to.path == "/authentication/AccountDeactivated" || to.path == "/authentication/DisapprovedAccount")
        )
  {
    if(user_roles.includes('HEI Registrar')){
      next({name: 'mainApp'});
    }
    else{
      next({name: "adminApp"});
    }
  }
  else if(store.getters.isAuthenticated && to.matched.some(record => record.meta.permission === "hei-auth") && !store.getters.getUser.institutions.length){
    next({name: "SignUpInstitutionInfo"});
  }
  else{
    next();
  }
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  // if (to.name) {
  //   // Start the route progress bar.
  //   // NProgress.start(800);
  // }

  if((to.matched.some(record => record.meta.permission === "hei-auth") || to.matched.some(record => record.meta.permission === "admin-auth")) && !store.getters.isAuthenticated){
    next({name: "SignIn"});
  }
  else if(store.getters.isAuthenticated){
    if((to.path == "/authentication/Complete/SignUp" || to.path == "/authentication/SignIn") && institutions.length){
      if(user_roles.includes('HEI Registrar')){
        next({name: 'mainApp'});
      }
      else{
        next({name: "adminApp"});
      }
    }

    if(to.matched.some(record => record.meta.permission === "admin-auth") && user_roles.includes('HEI Registrar')){
      next({name: 'mainApp'});
    }

    else{
      next();
    }


    next();
  }
  else if(to.matched.some(record => record.meta.permission === "guest")){

    if(to.name === 'Claim Section' && store.getters.GET_CSS_RESPONSE == 'false'){
      next({ name: 'Claim Section Starter Page' });
    }

    if(to.name === 'Client Statisfaction Survey' && !store.getters.GET_ACTIVE_RECEIVER){
      next({ name: 'Claim Section Starter Page' })
    }

    next();
  }
  else{
    next();
  }
});


// router.afterEach(() => {
//   // Complete the animation of the route progress bar.
//   NProgress.done();
// });

export default router;
