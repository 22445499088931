import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import moment from 'moment';
import VueEasyLightbox from 'vue-easy-lightbox';
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueMask from 'v-mask'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'


import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import FilePond plugins styles (if needed)
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Import FilePond component and plugins
import vueFilePond from 'vue-filepond';
// Import the plugins you want to use
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

const FilePond = vueFilePond(FilePondPluginImagePreview, FilePondPluginFileValidateType);

import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";

/** Vue filters starts */
Vue.prototype.moment = moment;

Vue.filter('lower', function(text){
    return text.toLowerCase();
});

 // Feb 25, 2021 10:19 AM
Vue.filter('formatDate', function(date){
    return moment(date).format('lll');
});

 // 30 minutes ago
Vue.filter('relativeFormat', function(date){
    return moment(date).fromNow();
});

 // February 25, 2021
Vue.filter('regularDate', function(date){
    return moment(date).format('LL');
});

 // February 25, 2021 10:19 AM
Vue.filter('regularDateWithTime', function(date){
    return moment(date).format('LLL');
});

 // Today at 10:20 AM
Vue.filter('calendarDate', function(date){
    return moment(date).calendar();
});

Vue.filter('dateOnly', function(date){
    return moment(date).format('MM-DD-YYYY');
});

Vue.filter('month', function(date){
    return moment(date).format('MMMM YYYY');
});

Vue.filter('full_month', function(date){
    return moment(date).format('MMMM');
});

Vue.filter('full_year', function(date){
    return moment(date).format('YYYY');
});

Vue.filter('count_date', function(date){
    return moment().diff(date, "days");
});
/** Vue filters ends */

/** V-Mask Starts */
Vue.use(VueMask);
/** V-Mask Ends */


/** Vuetify Tip-tap Rich Text Editor */
// const Vuetify = new vuetify()
Vue.use(TiptapVuetifyPlugin, {
    vuetify, iconsGroup: 'mdi'
})
/** Vuetify Tip-tap Rich Text Editor End */


/**
 ** Vue Toast Notifications Configurations
 */
 const options = {
    newestOnTop: true,
    position: 'bottom-left',
    maxToasts: 20,
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    rtl: false,
    transition: "Vue-Toastification__slideBlurred",
    // filterBeforeCreate: (toast, toasts) => {
    //     if (toasts.filter(
    //       t => t.type === toast.type
    //     ).length !== 0) {
    //       return false;
    //     }
    //     return toast;
    //   }
};


Vue.use(Toast, options);


Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("Cropper", Cropper);
Vue.component("VueEasyLightbox", VueEasyLightbox)
Vue.component("file-pond", FilePond);

Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(PerfectScrollbar)

new Vue({
  vuetify,
  store,
  router,

  created(){
    // Check if the user has already visited today
    const lastVisitDate = localStorage.getItem('lastVisitDate');
    const today = new Date();
    if (!lastVisitDate || lastVisitDate !== today.toDateString()) {
      // If it's the first visit of the day, show the preloader
      this.$store.commit('SET_PRELOADER_STATE', true);
    }

    localStorage.setItem('lastVisitDate', today.toDateString());
    window.addEventListener('load', () => {
        setTimeout(() => {
            this.$store.commit('SET_PRELOADER_STATE', false);
        }, 3000);
    })
  },

  render: (h) => h(App),
}).$mount("#app");
